var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card__box" }, [
    _c(
      "div",
      {
        staticClass: "card__wrapper",
        class: { "card__wrapper--active": _vm.isOurs }
      },
      [
        _c("div", { staticClass: "card__top" }, [
          _c("div", { staticClass: "card__row" }, [
            _c("div", { staticClass: "card__price" }, [
              _vm._v("$ " + _vm._s(_vm.obj.price))
            ]),
            _c("div", { staticClass: "card__cell" }, [
              _c(
                "div",
                {
                  staticClass: "card__button button__icon button__icon--border",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c("Multiselect", {
                    staticClass: "mSelect-dropdown",
                    attrs: {
                      options: _vm.list,
                      searchable: false,
                      "show-labels": false,
                      "reset-after": true,
                      value: _vm.item,
                      placeholder: "",
                      "open-direction": "bottom"
                    },
                    on: { select: _vm.actions }
                  })
                ],
                1
              ),
              _vm.selectionActive
                ? _c(
                    "div",
                    {
                      staticClass:
                        "card__button button__icon button__icon--border",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.select($event)
                        }
                      }
                    },
                    [
                      _c("i", {
                        class: {
                          "ri-checkbox-fill": _vm.obj.isSelected,
                          "ri-checkbox-blank-line": !_vm.obj.isSelected
                        }
                      })
                    ]
                  )
                : _vm._e()
            ])
          ])
        ]),
        _c("div", { staticClass: "card__middle" }, [
          _c("div", { staticClass: "card__field card__field--dark" }, [
            _c("div", { staticClass: "card__street" }, [
              _vm._v(_vm._s(_vm.obj.street) + ", " + _vm._s(_vm.obj.houseNo))
            ]),
            _c("div", { staticClass: "card__district" }, [
              _c("i", { staticClass: "i-location i-location--blue" }),
              _vm.obj.districtName
                ? _c("span", { staticClass: "card__span" }, [
                    _vm._v(_vm._s(_vm.obj.districtName))
                  ])
                : _c("span", { staticClass: "card__span" }, [
                    _vm._v(_vm._s(_vm.obj.settlement))
                  ])
            ])
          ]),
          _c("div", { staticClass: "card__row card__tags" }, [
            _c("div", { staticClass: "card__tag card__tag--yellow" }, [
              _c("span", { staticClass: "card__span" }, [
                _vm._v(_vm._s(_vm.obj.motivation))
              ])
            ]),
            _c("div", { staticClass: "card__tag card__tag--green" }, [
              _c("span", { staticClass: "card__span" }, [
                _vm._v(_vm._s(_vm._f("propertyStatus")(_vm.obj.propertyStatus)))
              ])
            ]),
            _c("div", { staticClass: "card__tag card__tag--red" }, [
              _c("span", { staticClass: "card__span" }, [
                _vm._v(_vm._s(_vm.obj.contract))
              ])
            ])
          ])
        ]),
        _c(
          "div",
          { staticClass: "card__bottom" },
          [
            _c("div", { staticClass: "card__id" }, [
              _vm._v("ID " + _vm._s(_vm.obj.id))
            ]),
            _vm._l(_vm.obj.relations, function(rel, i) {
              return _c("Relation", {
                key: i,
                attrs: { relation: rel, type: "card-tile" }
              })
            }),
            _c("div", { staticClass: "card__icoWrapper" }, [
              _c(
                "div",
                {
                  staticClass: "card__agent",
                  class: {
                    "card__agent--blocked": _vm.obj.isResponsibleBlocked
                  }
                },
                [
                  _c("i", { staticClass: "i-employees card__agent-icon" }),
                  _c("span", { staticClass: "card__agent-text" }, [
                    _vm._v(_vm._s(_vm.obj.responsibleName))
                  ])
                ]
              ),
              _c("i", {
                staticClass: "i-image card__photo",
                class: { "card__photo--blue": _vm.obj.photos.length }
              })
            ])
          ],
          2
        ),
        _c("div", { staticClass: "card__fields" }, [
          _c("div", { staticClass: "card__field" }, [
            _c("div", { staticClass: "card__text" }, [
              _c("span", [_vm._v("Кімнат")]),
              _vm.obj.type === "house"
                ? _c("span", [_vm._v("/Поверхів")])
                : _vm._e()
            ]),
            _c("div", { staticClass: "card__text" }, [
              _c("span", [_vm._v(_vm._s(_vm.obj.noOfRooms))]),
              _vm.obj.type === "house"
                ? _c("span", [_vm._v("/" + _vm._s(_vm.obj.floors))])
                : _vm._e()
            ])
          ]),
          _c("div", { staticClass: "card__field" }, [
            _c("div", { staticClass: "card__text" }, [
              _vm.obj.type === "commerce"
                ? _c("span", [_vm._v("Призначення")])
                : _c("span", [_vm._v("Вид/Тип")])
            ]),
            _vm.obj.type === "apartment"
              ? _c("div", { staticClass: "card__text" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("apartmentMaterial")(_vm.obj.material)) +
                      "/" +
                      _vm._s(_vm._f("apartmentType")(_vm.obj.buildingType)) +
                      " "
                  )
                ])
              : _vm._e(),
            _vm.obj.type === "house"
              ? _c("div", { staticClass: "card__text" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("buildingMaterial")(_vm.obj.material)) +
                      "/" +
                      _vm._s(_vm._f("buildingType")(_vm.obj.buildingType)) +
                      " "
                  )
                ])
              : _vm._e(),
            _vm.obj.type === "commerce"
              ? _c("div", { staticClass: "card__text" }, [
                  _vm._v(_vm._s(_vm._f("commerceType")(_vm.obj.buildingType)))
                ])
              : _vm._e()
          ]),
          _vm.obj.type !== "house"
            ? _c("div", { staticClass: "card__field" }, [
                _c("div", { staticClass: "card__text" }, [_vm._v("Поверх")]),
                _c("div", { staticClass: "card__text" }, [
                  _vm._v(_vm._s(_vm.obj.floor) + "/" + _vm._s(_vm.obj.floors))
                ])
              ])
            : _vm._e(),
          _c("div", { staticClass: "card__field" }, [
            _c("div", { staticClass: "card__text" }, [_vm._v("Площа м²")]),
            _c("div", { staticClass: "card__text" }, [
              _vm._v(
                _vm._s(_vm.obj.squareTotal) +
                  "/" +
                  _vm._s(_vm.obj.squareLiving) +
                  "/" +
                  _vm._s(_vm.obj.squareKitchen)
              )
            ])
          ]),
          _c("div", { staticClass: "card__field card__field--blue " }, [
            _c("div", { staticClass: "card__text" }, [
              _vm._v("Причина видалення")
            ]),
            _c("div", { staticClass: "card__text" }, [
              _vm._v(_vm._s(_vm.archivedReason))
            ])
          ]),
          _c("div", { staticClass: "card__field card__field--blue" }, [
            _c("div", { staticClass: "card__text" }, [_vm._v("Видалено до")]),
            _c("div", { staticClass: "card__text" }, [
              _c("i", { staticClass: "i-calendar" }),
              _vm.obj.archivedTill
                ? _c("div", [
                    _vm._v(_vm._s(_vm._f("date")(_vm.obj.archivedTill)))
                  ])
                : _vm._e()
            ])
          ]),
          _c("div", { staticClass: "card__field card__field--blue" }, [
            _c("div", { staticClass: "card__text" }, [
              _vm._v("Дата видалення")
            ]),
            _c("div", { staticClass: "card__text" }, [
              _c("i", { staticClass: "i-calendar" }),
              _vm._v(" " + _vm._s(_vm._f("date")(_vm.obj.archivedAt)) + " ")
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }