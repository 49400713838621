<template>
  <div class="card__box">
    <div class="card__wrapper" :class="{'card__wrapper--active': isOurs}">
      <div class="card__top">
        <div class="card__row">
          <div class="card__price">$ {{ obj.price }}</div>
          <div class="card__cell">
            <div class="card__button button__icon button__icon--border" @click.stop.prevent>
              <Multiselect
                class="mSelect-dropdown"
                :options="list"
                :searchable="false"
                :show-labels="false"
                :reset-after="true"
                :value="item"
                placeholder=""
                open-direction="bottom"
                @select="actions"
              />
            </div>
            <div v-if="selectionActive" class="card__button button__icon button__icon--border" @click.stop.prevent="select">
              <i :class="{'ri-checkbox-fill': obj.isSelected, 'ri-checkbox-blank-line': !obj.isSelected}"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="card__middle">
        <div class="card__field card__field--dark">
          <div class="card__street">{{ obj.street }}, {{ obj.houseNo }}</div>
          <div class="card__district">
            <i class="i-location i-location--blue"></i>
            <span class="card__span" v-if="obj.districtName">{{ obj.districtName }}</span>
            <span class="card__span" v-else>{{ obj.settlement }}</span>
          </div>
        </div>
        <div class="card__row card__tags">
          <div class="card__tag card__tag--yellow">
            <span class="card__span">{{ obj.motivation }}</span>
          </div>
          <div class="card__tag card__tag--green">
            <span class="card__span">{{ obj.propertyStatus | propertyStatus }}</span>
          </div>
          <div class="card__tag card__tag--red">
            <span class="card__span">{{ obj.contract }}</span>
          </div>
        </div>
      </div>
      <div class="card__bottom">
        <div class="card__id">ID {{ obj.id }}</div>
        <Relation v-for="(rel, i) in obj.relations" :relation="rel" type="card-tile" :key="i"/>
        <div class="card__icoWrapper">
          <div class="card__agent"  :class="{'card__agent--blocked': obj.isResponsibleBlocked}">
            <i class="i-employees card__agent-icon"></i>
            <span class="card__agent-text">{{ obj.responsibleName }}</span>
          </div>
          <i class="i-image card__photo" :class="{'card__photo--blue': obj.photos.length}"></i>
        </div>
      </div>
      <div class="card__fields">
        <div class="card__field">
          <div class="card__text">
            <span>Кімнат</span>
            <span v-if="obj.type === 'house'">/Поверхів</span>
          </div>
          <div class="card__text">
            <span>{{ obj.noOfRooms }}</span>
            <span v-if="obj.type === 'house'">/{{ obj.floors }}</span>
          </div>
        </div>
        <div class="card__field">
          <div class="card__text">
            <span v-if="obj.type === 'commerce'">Призначення</span>
            <span v-else>Вид/Тип</span>
          </div>
          <div v-if="obj.type === 'apartment'" class="card__text">
            {{ obj.material | apartmentMaterial }}/{{ obj.buildingType | apartmentType }}
          </div>
          <div v-if="obj.type === 'house'" class="card__text">
            {{ obj.material | buildingMaterial }}/{{ obj.buildingType | buildingType }}
          </div>
          <div v-if="obj.type === 'commerce'" class="card__text">{{ obj.buildingType | commerceType }}</div>
        </div>
        <div v-if="obj.type !== 'house'" class="card__field">
          <div class="card__text">Поверх</div>
          <div class="card__text">{{ obj.floor }}/{{ obj.floors }}</div>
        </div>
        <div class="card__field">
          <div class="card__text">Площа м²</div>
          <div class="card__text">{{ obj.squareTotal }}/{{ obj.squareLiving }}/{{ obj.squareKitchen }}</div>
        </div>
        <div class="card__field card__field--blue ">
          <div class="card__text">Причина видалення</div>
          <div class="card__text">{{ archivedReason }}</div>
        </div>
        <div class="card__field card__field--blue">
          <div class="card__text">Видалено до</div>
          <div class="card__text">
            <i class="i-calendar"></i>
            <div v-if="obj.archivedTill">{{ obj.archivedTill | date }}</div>
          </div>
        </div>
        <div class="card__field card__field--blue">
          <div class="card__text">Дата видалення</div>
          <div class="card__text">
            <i class="i-calendar"></i>
            {{ obj.archivedAt | date }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import ModalArchive from '@/components/ModalArchive'
import Relation from '@/components/Relation'

export default {
  name: 'ArchiveCardObjectApartment',
  props: ['obj'],
  computed: {
    selectionActive() {
      switch (this.obj.type) {
        case 'apartment':
          return this.$store.getters['archive/objects/apartments/selectionActive']
        case 'house':
          return this.$store.getters['archive/objects/houses/selectionActive']
        case 'commerce':
          return this.$store.getters['archive/objects/commerce/selectionActive']
        default:
          return false
      }
    }
  },
  components: {
    Multiselect,
    Relation,
  },
  data: () => ({
    list: ['Відновити'],
    item: '',
    userRole: null,
    archivedReason: null,
    constants: null,
    isOurs: false
  }),
  created() {
    this.constants = this.$store.getters.constants
    this.userRole = this.$store.getters.user.role
    this.archivedReason = this.constants.ARCHIVED_REASONS.find(s => s.id === this.obj.archivedReason).name

    const source = this.$store.getters.constants.PROPERTY_SOURCES[3].children.map(p => p.id)
    this.isOurs = !source.some(prop => prop === this.obj.source)

    if (this.userRole === 4) {
      this.list.push('Редагувати', 'Видалити')
    } else if (this.obj.responsibleID === this.$store.getters.user.id) {
      this.list.push('Редагувати')
    }
  },
  methods: {
    async actions(option) {
      const id = this.obj.id
      if (option === 'Видалити') {
        try {
          switch (this.obj.type) {
            case 'apartment':
              await this.$store.dispatch('archive/objects/apartments/delete', id)
              break
            case 'house':
              await this.$store.dispatch('archive/objects/houses/delete', id)
              break
            case 'commerce':
              await this.$store.dispatch('archive/objects/commerce/delete', id)
              break
          }
        } catch {}
      } else if (option === 'Відновити') {
        try {
          switch (this.obj.type) {
            case 'apartment':
              await this.$store.dispatch('archive/objects/apartments/restore', id)
              break
            case 'house':
              await this.$store.dispatch('archive/objects/houses/restore', id)
              break
            case 'commerce':
              await this.$store.dispatch('archive/objects/commerce/restore', id)
              break
          }
        } catch {}
      } else if (option === 'Редагувати') {
        this.$modal.display(
          ModalArchive,
          {info: this.obj, entity: 'objects', action: 'update'},
          {transition: 'none', classes: 'modalArchive', width: '500px', height: '350px', name: 'ModalArchive'}
        )
      }
    },
    async select() {
      const id = this.obj.id
      let path = ''
      switch (this.obj.type) {
        case 'apartment':
          path = 'archive/objects/apartments/selectObject'
          break
        case 'house':
          path = 'archive/objects/houses/selectObject'
          break
        case 'commerce':
          path = 'archive/objects/commerce/selectObject'
          break
      }
      this.$store.commit(path, {id, isSelected: !this.obj.isSelected})
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
@import './style.scss';
.card__button {
  .ri-checkbox-fill, .ri-checkbox-blank-line {
    font-size: 20px;
  }
}
</style>
